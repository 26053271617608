html {
  overflow-y: scroll;
}

.alert{
  font-size: 18px !important;
}

.oljekollen {

  tr {
    &.priority {
      background-color: #ffcaca;

      &:hover {
        background-color: #ff9494;
      }
    }
  }

  .with-panic {
    display: none;
  }

  .center {
    text-align: center;
  }

  .rounded {
    border-radius: 6px;
    @media (min-width: $screen-sm) {
      margin-right: 20px;
    }
  }

  .panel-default {
    .nobg {
      background-color: white;
    }
  }

  .big-button-holder {
    a {
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }
    margin-bottom: 20px;

    .big-button {
      text-align: center;
      padding: 30px;
      border: 1px solid gray;

      //text-decoration: none;
      &:hover {
        text-decoration: none;
      }

      .glyphicon {
        font-size: 72px;
        display: block;
        margin-bottom: 20px;
      }
    }
  }

    form {
      .helper {
        float: right;
        //display: inline-block;
        position: relative;

        .icon {
          width: 22px;
          height: 22px;
          border: 1px solid gray;
          border-radius: 100%;
          text-align: center;
          font-weight: bold;
          color: orange;
          border-width: 2px;
          line-height: 18px;
          cursor: pointer;
          font-size: 14px;
        }

        .text {
          position: absolute;
          right: 24px;
          font-size: 14px;
          display: none;
          background-color: white;
          padding: 20px;
          border: 2px solid gray;
          border-radius: 4px;
          transform: translate(0,-50%);
          width: 80vw;
          max-width: 280px;
        }

        &:hover {
          .text {
            display: block;
          }
        }
      }
    }

    .front-panel {
      min-height: 265px;

      @media (max-width: $screen-md) {
        min-height: 0;
      }
    }

  .navbar {
    margin-bottom: 0;

    @media (max-width: $screen-sm) {
      //min-height: 0;
      margin-bottom: 20px;
    }

    &.navbar-second {
      background-color: #6b6b6b;
      margin-bottom: 20px;

      a {
        color: #fff;

        &:hover,
        &:focus {
          background-color: #2e3436;
        }
      }
    }
  }

  //Fix logo padding
  .topbar-logo {
    padding: 7px 15px !important;

    img {
      max-height: 100%;
    }
  }

  //Add margin to top
  .margin-top {
    margin-top: 20px;
  }

  //Login tabs
  .nav-tabs {
    li {
      @include resp('mobile') {
        width: 100%;
      }
    }
  }

  //Remove bottom margin from navbar
  .page-header {
    margin-top: 22px;
  }

  label {
    font-weight: bold;
  }

  .novisible {
    display: none;
  }

  .alert {
    h2 {
      margin-top: 0px;
    }
  }

  .btn-url {
    padding: 0;

    a {
      padding: 8px 12px;
      color: white;
      display: block;
      width: 100%;
      height: 100%;

      &:hover {
        text-decoration: none;
      }
    }
  }


  .footer-content {
    margin-top: 20px;
    border-top: 1px solid #cecece;
    padding: 30px 0;
    text-align: center;
  }
}

table tr.is-dragover {
  background: orange !important;
  color: #fff;
}

